.users .ReactTable .rt-tbody .rt-td:last-child {
  text-align: right;
}

.forgot {
  padding-top: 30px;
  /* padding-bottom: 30px; */
}
.forgot .nav-link {
  text-transform: uppercase;
  /* font-weight: bold; */
  color: #189A62;
  text-decoration: none !important;
}
.forgot-password > .content {
  padding-top: 22vh;
}
.forgot-password {
  padding-top: 22vh;
}
/* .displayblock {
    display: block;
}
.dispaynone {
    display: none;
} */

.main-content.corp-users .add-client button {
  text-transform: uppercase;
}
.add-client {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
}
.modal-body .card .content {
  padding-bottom: 55px;
}
.btn:disabled,
.btn[disabled],
.btn.disabled {
  opacity: 0.5 !important;
  filter: alpha(opacity=50);
}
.form-group.phone-input input {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  color: #565656;
  padding: 8px 12px;
  height: 40px;
  box-shadow: none;
}
.form-group.phone-input input:focus {
  background-color: #ffffff;
  border: 1px solid #aaaaaa;
  box-shadow: none;
  outline: 0 !important;
  color: #333333;
}
.sidebar .logo {
  padding: 12px 23px;
  margin: 0;
  box-shadow: inset -1px 0px 0px 0px #cfcfca;
  position: relative;
  z-index: 4;
  display: block;
}

.sidebar .logo img {
  max-width: 200px;
}
/* .sidebar .logo:before, .bootstrap-navbar .logo:before {
    bottom: -8px;
} */
.react-select .react-select__control {
  background-color: #ffffff;
  border-color: #d9d9d9 #ccc #e3e3e3;
  border-radius: 4px;
  border: 1px solid #ccc;
  min-height: 40px;
  height: 40px;
  box-sizing: border-box;
  border-collapse: separate;
  display: table;
  width: 100%;
}
.react-select .react-select__control.react-select__control--is-focused,
.react-select .react-select__control.react-select__control--is-focused:hover {
  background-color: #ffffff;
  border: 1px solid #aaaaaa;
  box-shadow: none;
  outline: 0 !important;
  color: #333333;
}
.react-select__value-container--has-value .react-select__multi-value__label {
  color: #189A62 !important;
}
.react-select.info .react-select__multi-value .react-select__multi-value__remove {
  color: #189A62;
  border-left: 1px solid #189A62;
}
.react-select.info .react-select__multi-value {
  border: 1px solid #189A62;
}
.react-select.info .react-select__multi-value .react-select__multi-value__remove:hover {
  color: #189A62 !important;
  background-color: transparent;
}
.form-group.phone-input input[disabled] {
  background-color: #f5f5f5;
  color: #444;
  cursor: not-allowed;
}
.form-group.phone-input input::placeholder {
  color: #444;
  opacity: 1;
}

.tech-schedule .ReactTable .rt-thead .rt-th:last-child {
  text-align: left;
}
.react-time-picker__wrapper {
  border: thin solid #e3e3e3;
  padding: 8px 5px;
  border-radius: 4px;
}
.test-description li {
  padding: 10px 0;
}
.text-outline {
  border: 2px solid #c5cad3;
  border-radius: 5px;
  padding: 10px;
}
.test-quest {
  background-color: #c5cad325;
  padding: 10px;
  border-radius: 5px;
}
.test-quest .checkbox {
  margin: 15px 0;
}
.test-quest .checkbox input[type="checkbox"]:checked + label::after {
  color: #ea1b27;
}
.upload-file {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}
.upload-file .btn,
.booking-card .btn {
  color: #ea1b27;
  font-weight: bold;
}
.upload-file .btn:hover,
.booking-card .btn:hover {
  color: #be151d;
}
.booking-card-person {
  /* display: flex;
    justify-content: space-between;
    align-items: flex-start; */
  padding: 12px 0px;
  /* background-color:#c5cad325; */
  /* border-bottom: 2px solid #EDF0F5; */
}
.booking-checkout {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px 0px;
  background-color: #c5cad325;
  border-bottom: 2px solid #edf0f5;
  color: #989ca3;
}
.booking-services {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 5px 0;
}
.booking-card-services .btn-round {
  padding: 3px;
}
.booking-card-services {
  padding: 15px 5px;
}
.text-bold {
  font-weight: bold;
}
input[type="radio"]:checked + label::after {
  color: #ea1b27 !important;
}
.schedule-selected {
  padding-bottom: 100px;
}
.create-appt .radio {
  margin-bottom: 0px;
  padding: 12px 0;
}

.booking-checkout .text-bold:first-child {
  padding-right: 30px;
}
.booking-card-person:last-child,
.booking-services:last-child {
  border-bottom: 0;
}
.service-price {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  color: #000000;
}
.d-flex {
  display: flex;
}

.questionnaire-box {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.questionnaire-box .btn-round {
  padding: 8px;
}
.questionnaire-box .form-group {
  margin-bottom: 0;
}
.holiday-closures-card .DayPicker {
  font-size: 1.5rem;
}
.holiday-closures-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.mapboxgl-marker .marker-btn {
  background: transparent;
  border: none;
  cursor: pointer;
}
.mapboxgl-marker .marker-btn:focus-visible {
  border: none !important;
  outline: none;
}

.marker-btn img {
  width: 48px;
  height: 48px;
  object-fit: contain;
}
.marker-btn.marker-tech img {
  height: 45px;
}
.appt-popup {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  align-items: center;
}
.appointments .mapboxgl-popup-content {
  padding: 20px;
}
.appointments .mapboxgl-popup-content .card {
  margin-bottom: 0;
}
.dashboard-map .mapboxgl-popup-content {
  padding: 20px;
}
.dashboard-map .mapboxgl-popup-content .card {
  margin-bottom: 0;
}
.text-editor .rdw-editor-wrapper {
  min-height: 500px;
}
.text-editor .card {
  padding-bottom: 50px;
}
.system-settings .rdw-editor-wrapper {
  min-height: 500px;
}
.appt-popup-header .avatar {
  width: 45px;
  height: 45px;
  object-fit: cover;
  margin-right: 20px;
}
.appt-popup-header {
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .live-chat-modal .modal-dialog {
    width: 700px;
  }
}
@media (min-width: 768px) {
  .assign-job-modal .modal-dialog {
    width: 1140px;
    margin: 30px auto;
  }
}
.assign-appt {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px 0px;
  /* background-color:#c5cad325; */
  border-bottom: 2px solid #edf0f5;
  color: #000000;
  font-weight: bold;
}
.add-service {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
}

.select-date-time {
  padding: 20px 0;
  border-top: 1px solid #edf0f5;
  border-bottom: 1px solid #edf0f5;
}
.select-date-time .DayPicker {
  font-size: 1.4rem;
}

.DayPickerInput {
  width: 100%;
}
.DayPickerInput input {
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  color: #565656;
  padding: 8px 12px;
  height: 40px;
  box-shadow: none;
  width: 100%;
}
.location-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.show-icon {
  position: absolute;
  right: 10px;
  top: 33px;
  width: 25px;
}
.rdw-link-modal,
.rdw-embedded-modal {
  height: auto;
  min-height: 205px;
}
.order-info-border {
  border-top: 2px solid #edf0f5;
}

@media (min-width: 991px) and (max-width: 1280px) {
  .rbc-toolbar .rbc-toolbar-label {
    font-size: 24px !important;
  }
  .rbc-toolbar {
    flex-direction: column;
  }
  .rbc-toolbar-label {
    margin: 12px 0;
  }
}
.modal-body .add-promocode .card .content {
  padding-bottom: 0px !important;
}
.service-editor .rdw-editor-main {
  padding: 10px 15px;
}
.service-editor .rdw-editor-wrapper {
  min-height: 340px;
  border: 1px solid rgb(240, 240, 240);
}
.service-image {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.fileInput {
  border-bottom: 4px solid lightgray;
  border-right: 4px solid lightgray;
  border-top: 1px solid black;
  border-left: 1px solid black;
  padding: 10px;
  margin: 15px;
  cursor: pointer;
}

.imgPreview {
  text-align: center;
  margin: 5px 15px;
  height: 200px;
  width: 500px;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  border-top: 5px solid gray;
  border-bottom: 5px solid gray;
}

.previewComponent img {
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 250px;
  object-fit: contain;
}

.previewText {
  width: 100%;
  margin-top: 20px;
}

.submitButton {
  padding: 12px;
  margin-left: 10px;
  background: white;
  border: 4px solid lightgray;
  border-radius: 15px;
  font-weight: 700;
  font-size: 10pt;
  cursor: pointer;
}

.centerText {
  text-align: center;
  width: 500px;
}

.upload {
  padding: 10px 0;
}
.upload input[type="file"],
.upload input[type="submit"] {
  display: none;
}
.custom-file-upload {
  /* border: 1px solid #ccc; */
  background-color: #c42f18 !important;
  color: #fff;
  display: inline-block;
  padding: 8px 12px;
  /* cursor: pointer; */
  border-radius: 3px;
}
.cursor-pointer {
  cursor: pointer;
}
.disable {
  /* filter: brightness(50); */
  background-color: #e07a6a !important;
}
.upload-box {
  display: flex;
}
.form-group.phone-input input::placeholder {
  color: #c3c3c3;
  opacity: 1;
  filter: alpha(opacity=100);
}
.swal2-styled.swal2-cancel {
  font-size: 1.5em !important;
}
.swal2-styled.swal2-confirm {
  font-size: 1.5em !important;
}
.swal2-content {
  font-size: 1.5em;
}
.feedback .ReactTable .rt-thead .rt-th:last-child {
  text-align: left;
}
.main-content.service .card,
.main-content.promo .card {
  margin-bottom: 30px;
}
.main-content.drop-off .card,
.main-content.edit-user .card,
.main-content.service-locations .card {
  margin-bottom: 12px;
}
.closure-interval .react-time-picker {
  width: 100%;
}
.closure-interval .react-time-picker__inputGroup__input {
  height: auto;
  padding: 1px;
}
.closure-interval .react-time-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.05em;
}
