.avvy-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avvy-loader .heartbeat-loader {
  margin-left: -6px;
}

.avvy-loader .loader-pulse {
  fill: transparent;
  stroke: #189a62;
  stroke-width: 8;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.avvy-loader .loader-style {
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-fill-mode: backwards;
  stroke-dasharray: 200;
  animation-iteration-count: infinite;
  animation-name: loading;
}

@keyframes loading {
  0% {
    stroke-dashoffset: 800;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.avvy-loader .loader-opacity {
  opacity: 0.3;
}
