.timeline {
  /* half width of timeline*/
  --status-size: 9px;
  --status-offset: 5px;
}

.line {
  position: relative;
  top: var(--status-offset);
  width: calc(100% - 1px);
  background-color: lightgrey;
  overflow: hidden;
}

.line-progression {
  background: linear-gradient(90deg, #53ddd6, #29b9ad);
  height: 2px;
  animation: trackline-progress-load 2s 300ms ease-out both;
}

@keyframes trackline-progress-load {
  0% {
    transform: translateX(-100%);
  }
}

.timeline > ol {
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  padding-inline-start: initial;
}

.milestone {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.timeline-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.timeline-status {
  width: var(--status-size);
  height: var(--status-size);
  background: lightgrey;
  border-radius: 50%;
}

.status--checked {
  background: #29b9ad;
}

.milestone-label {
  position: absolute;
  top: 20px;
  text-align: center;
}

.pulse {
  background-color: #99ded8;
  width: var(--status-size);
  height: var(--status-size);
  position: absolute;
  border-radius: 50%;
  animation-name: myo-trackline-pulse;
  animation-duration: 2500ms;
  animation-timing-function: ease-in;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  z-index: -1;
}

@keyframes myo-trackline-pulse {
  0%,
  100% {
    transform: scale(1);
    opacity: 0;
  }
  1% {
    opacity: 0.4;
  }
  70% {
    opacity: 0.2;
  }
  99.999% {
    transform: scale(6);
    opacity: 0;
  }
}

@keyframes point-active {
  0%,
  50% {
    transform: scale(1.2);
    background: "#189A62";
  }
  100% {
    transform: scale(1);
    background: "#002766";
  }
}

/*Timeline SVG*/
.timeline-svg {
  overflow: visible;
}

@keyframes svg-line-progression {
  100% {
    x: 0;
  }
}

.mask-line {
  animation: svg-line-progression 2s 300ms ease-out both;
}

.svg-pulse {
  animation-name: svg-trackline-pulse;
  animation-duration: 2500ms;
  animation-timing-function: ease-in;
  animation-delay: 1s;
  animation-iteration-count: infinite;
}

@keyframes svg-trackline-pulse {
  0%,
  100% {
    transform: scale(1);
    opacity: 0;
  }
  1% {
    opacity: 0.4;
  }
  70% {
    opacity: 0.2;
  }
  99.999% {
    transform: scale(6);
    opacity: 0;
  }
}
